---
# brand
title: Talint Match

# top level site navigation
nav:
  home: Rubrik
  rubrics: Rubrics
  jobs: Jobs
  candidates: Candidates
  login: Sign In
  logout: Sign Out

# used in combination with "<action>Model" keys
# intended to dry up "create item", "create other", etc.
models:
  job: job
  jobs: jobs
  candidate: candidate
  candidates: candidates
  rubric: rubric
  rubrics: rubrics
  criterion: criterion
  criteria: criteria
  evaluation: evaluation
  evaluations: evaluations

# used for buttons and menu items
# note that "<action>Model" keys should be used with models keys
actions:
  login: Sign In
  logout: Sign Out
  create: Create
  view: View
  edit: Edit
  delete: Delete
  update: Update
  confirm: Confirm
  cancel: Cancel
  save: Save
  import: Import
  importModel: Import {model}
  importFrom: Import from...
  importFromLoxo: Import from Loxo
  reimport: Reimport
  evaluate: Evaluate
  loadMore: Load More
  submit: Submit
  addModel: Add {model}
  previous: Previous
  next: Next

# used for prompts and alerts
messages:
  loading: Loading...
  noModel: There are currently no {model}
  importedAt: Imported {time}
  na: N/A
  notAvailable: Not available
  notFound: Not found
  confirm: Are you sure?
  criteriaWeights: Criteria weights should sum to 100 (currently {total})
  notImportedJob: Job must be imported before candidate can be imported

# form labels should have no suffix (eg: "email")
# placeholders should have "<field>Placeholder" suffix
# help text should have "<field>Help" suffix
forms:
  login:
    email: Email
    emailPlaceholder: Email
    password: Password
    passwordPlaceholder: Password
    showPassword: Show password

  jobsImport:
    id: Job ID

  fallback:
    selectPlaceholder: Select one...
    selectNoOptionsPlaceholder: No options

# component-specific copy only
components:
  pagination:
    prev: Prev
    next: Next

  textFromFileField:
    insertTextAction: Insert Text From a PDF...

# page-specific headings and copy only
# make sure keys that belong in actions or messages do not go here!
pages:
  home:
    title: Rubrik

  jobs:
    title: Jobs
  jobsImport:
    title: Import Job
  jobsEdit:
    candidatesTitle: Candidates

  candidatesEdit:
    evaluationsTitle: Evaluations
  candidatesImport:
    title: Import Candidates

  rubrics:
    title: Rubrics
  rubricsCreate:
    title: New Rubric
  rubricsEdit:
    criteriaTitle: Criteria

  login:
    title: Sign In
  logout:
    title: Sign Out
    confirm: Are you sure you want to sign out?
  notFound:
    title: Page Not Found
    message: The page you are looking for does not exist.

fields:
  job:
    companyName: Company
    salary: Salary
    createdAt: Created
    importedAt: Imported

  candidate:
    name: Name
    email: Email
    phone: Phone
    resume: Resume
    createdAt: Created
    importedAt: Imported
    evaluatedAt: Evaluated

  rubric:
    createdAt: Created
