import { type Ref, ref } from 'vue'
import { useApi } from '@/plugins/api'
import { CursorResponse } from '@@/types'

export default function usePaginatedResults<Results>(url: string) {
  const cursor: Ref<CursorResponse<Results>['next']> = ref()
  const hasMore: Ref<CursorResponse<Results>['more']> = ref(false)
  const results: Ref<CursorResponse<Results>['results']> = ref([])

  const { isLoading, execute } = useApi<Results[]>(
    url,
    {
      params: { page: cursor.value },
    },
    {
      immediate: true,
      onSuccess: (res: CursorResponse<Results>) => {
        cursor.value = res.next
        hasMore.value = res.more
        results.value.push(...res.results)
      },
    }
  )
  const loadMore = () => execute({ params: { page: cursor.value } })

  return {
    results,
    hasMore,
    loadMore,
    isLoading,
  }
}
