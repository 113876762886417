<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import {
  Alert,
  Btn,
  DateTimeFormat,
  DetailList,
  DForm,
  Empty,
  Loading,
  Panel,
} from '@/components'
import { getApi, useApi } from '@/plugins/api'
import { candidateFormSchema } from '@/forms'
import { useI18n } from 'vue-i18n'
import { Candidate, Evaluation } from '@@/types'
import PageLayout from '@/components/PageLayout/PageLayout.vue'

const route = useRoute()

const { t } = useI18n()

const api = getApi()

const id = route.params.id
const candidateId = route.params.candidateId

const { data: job, isLoading: isLoadingJob } = useApi<Candidate>(`/jobs/${id}`)
const {
  data: candidate,
  isLoading: isLoadingCandidate,
  execute: executeCandidate,
} = useApi<Candidate>(`/jobs/${id}/candidates/${candidateId}`)
const {
  data: evaluations,
  isLoading: isLoadingEvaluations,
  execute: executeEvaluations,
} = useApi<Evaluation[]>(`/jobs/${id}/candidates/${candidateId}/evaluations`)

const isEvaluating = ref(false)

const isLoading = computed(
  () =>
    isLoadingJob.value || isLoadingCandidate.value || isLoadingEvaluations.value
)

const isImported = computed(() => {
  return candidate.value?.importedAt
})

const canEvaluate = computed(() => {
  return (
    job.value?.importedAt &&
    candidate.value?.importedAt &&
    candidate.value?.resume &&
    candidate.value?.resume.trim().length > 0
  )
})

const canImport = computed(() => {
  return job.value?.importedAt
})

const isSaving = ref(false)

const reimportCandidate = async () => {
  if (!job.value || !candidate.value) return
  if (!job.value.importedAt) return

  if (candidate.value.importedAt) {
    if (!confirm(t('messages.confirm'))) return
  }

  isSaving.value = true

  await api.post<Candidate>(
    `/jobs/${id}/candidates/import/${candidate.value.remoteId}`
  )
  await executeCandidate()

  isSaving.value = false
}

const updateCandidate = async (data: Partial<Candidate>) => {
  isSaving.value = true

  await api.put<Candidate>(`/jobs/${id}/candidates/${candidateId}`, {
    resume: data.resume,
    coverLetter: data.coverLetter ?? '',
  })

  isSaving.value = false
}

const evaluateCandidate = async () => {
  if (!canEvaluate.value) return

  isEvaluating.value = true

  const res = await api.post<Candidate>(
    `/jobs/${id}/candidates/${candidateId}/evaluate`
  )
  console.log('ding', res)
  executeEvaluations()

  isEvaluating.value = false
}
</script>

<template>
  <useHead>
    <title>{{ candidate?.name }}</title>
  </useHead>

  <PageLayout>
    <template #sticky-header>
      <PageHeader
        :title="candidate?.name"
        :breadcrumbs="[
          { text: $t('nav.jobs'), to: { name: 'jobs' } },
          {
            text: job?.name,
            to: { name: 'jobs-edit', params: { id: job?.id } },
          },
        ]"
        :loading="isLoading"
        actions-class="flex items-center gap-2"
      >
        <template #actions="{ loading }">
          <Btn
            :variant="!!candidate?.importedAt ? 'danger' : 'primary'"
            :disabled="loading || !canImport"
            :outline="!!candidate?.importedAt"
            @click="reimportCandidate"
          >
            {{
              !!candidate?.importedAt
                ? $t('actions.reimport')
                : $t('actions.import')
            }}
          </Btn>

          <Btn
            variant="primary"
            :disabled="isLoading || isEvaluating || !canEvaluate"
            class="flex items-center gap-2"
            @click="evaluateCandidate"
          >
            {{ $t('actions.evaluate') }} <Loading v-if="isEvaluating" />
          </Btn>
        </template>
      </PageHeader>
    </template>

    <Empty v-if="isLoading" :title="$t('messages.loading')" size="xl">
      <Loading />
    </Empty>

    <Empty v-else-if="!candidate" :title="$t('messages.notFound')" size="xl" />

    <div v-if="candidate">
      <Alert v-if="!canImport" variant="warning" class="mb-5">{{
        $t('messages.notImportedJob')
      }}</Alert>

      <section class="mb-10">
        <DetailList
          :entries="candidate"
          :fields="['createdAt', 'importedAt', 'evaluatedAt', 'status']"
          i18n-prefix="fields.candidate"
          class="mb-5"
        >
          <template #createdAt-value="{ value }">
            <DateTimeFormat v-if="value" :date="value" />
          </template>

          <template #importedAt-value="{ value }">
            <DateTimeFormat v-if="value" :date="value" />
          </template>

          <template #evaluatedAt-value="{ value }">
            <DateTimeFormat v-if="value" :date="value" />
          </template>
        </DetailList>

        <DForm
          :schema="candidateFormSchema"
          :initial-values="candidate"
          :loading="isLoading || isSaving || isEvaluating"
          :disabled="!isImported"
          :submit-text="$t('actions.save')"
          cancel-text=""
          @submit="updateCandidate"
        />
      </section>

      <section v-if="evaluations">
        <header>
          <h2 class="mb-5 text-base font-semibold">
            {{ $t('pages.candidatesEdit.evaluationsTitle') }}
          </h2>
        </header>

        <ul class="space-y-5">
          <li v-for="evaluation in evaluations" :key="evaluation.id">
            <div class="mb-3">Evaluated {{ evaluation.createdAt }}</div>

            <ul class="space-y-2">
              <Panel
                v-for="criterion in evaluation.criteria"
                :key="criterion.name"
                as="li"
                header-class="font-semibold flex items-center justify-between gap-5"
                body-class="text-muted"
              >
                <template #header>
                  <div>
                    {{ criterion.name }}
                  </div>

                  <div>{{ criterion.score }} / 100</div>
                </template>

                <template #default>
                  {{ criterion.description }}
                </template>
              </Panel>

              <Panel
                as="li"
                header-class="font-semibold flex items-center justify-between gap-5"
                body-class="text-muted"
              >
                <template #header>
                  <div>Conclusion</div>

                  <div>{{ evaluation.score }} / 100</div>
                </template>

                <template #default>
                  {{ evaluation.conclusion }}
                </template>
              </Panel>
            </ul>
          </li>
        </ul>

        <Empty
          v-if="evaluations.length === 0"
          :title="$t('messages.noModel', { model: 'evaluations' })"
          size="lg"
          muted
        />
      </section>
    </div>
  </PageLayout>
</template>
