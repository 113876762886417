<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getApi } from '@/plugins/api'
import {
  Button,
  Card,
  DateTimeFormat,
  DetailList,
  Empty,
  Loading,
  PageLayout,
} from '@/components'
import { Job } from '@@/types'
import { PhDownloadSimple } from '@phosphor-icons/vue'
import usePaginatedResults from '../usePaginatedResults'

const route = useRoute()
const vendor = computed(() => route.params.vendor)

const { results, hasMore, loadMore, isLoading } = usePaginatedResults<Job>(
  `/jobs/import/${vendor.value}`
)
const noResults = computed(() => results.value.length === 0)

const api = getApi()
const router = useRouter()
const importJob = async (id: string | number) => {
  const res = await api.post<Job[]>(`/jobs/${vendor.value}/${id}/import`)
  router.push({ name: 'jobs', query: { highlight: res.data[0].id } })
}
</script>

<template>
  <useHead>
    <title>{{ $t('pages.jobsImport.title') }}</title>
  </useHead>

  <PageLayout>
    <template #sticky-header>
      <PageHeader
        :title="$t('pages.jobsImport.title')"
        :breadcrumbs="[{ text: $t('nav.jobs'), to: { name: 'jobs' } }]"
      />
    </template>

    <Empty
      v-if="noResults && isLoading"
      :title="$t('messages.loading')"
      size="xl"
    >
      <Loading />
    </Empty>

    <Empty
      v-else-if="noResults && !isLoading"
      :title="$t('messages.noModel', { model: $t('models.jobs') })"
      size="xl"
      muted
    />

    <section v-else>
      <ul>
        <Card
          v-for="job in results"
          :key="job.remoteId"
          as="li"
          :class="{ 'opacity-50': job.importedAt }"
        >
          <template #title>
            <h4>
              {{ job.name }}
            </h4>
          </template>

          <template #actions>
            <Button
              v-if="!job.importedAt"
              size="sm"
              @click="importJob(job.remoteId)"
            >
              <PhDownloadSimple weight="regular" />
              {{ $t('actions.import') }}
            </Button>
          </template>

          <DetailList
            :entries="job"
            :fields="['companyName', 'salary', 'createdAt', 'importedAt']"
            i18n-prefix="fields.job"
          >
            <template #createdAt-value="{ value }">
              <DateTimeFormat v-if="value" :date="value" />
            </template>

            <template #importedAt-value="{ value }">
              <DateTimeFormat v-if="value" :date="value" />
            </template>
          </DetailList>
        </Card>
      </ul>

      <Button
        v-if="hasMore"
        class="w-full"
        :loading="isLoading"
        @click="loadMore()"
      >
        {{ $t('actions.loadMore') }}
      </Button>
    </section>
  </PageLayout>
</template>
