<script setup lang="ts">
import { useApi } from '@/plugins/api'
import {
  Button,
  Card,
  DateTimeFormat,
  DetailList,
  Empty,
  Loading,
} from '@/components'
import { Rubric } from '@@/types'
import { PhPlus, PhPencilSimpleLine } from '@phosphor-icons/vue'

const { data: rubrics, isLoading } = useApi<Rubric[]>('/rubrics')
</script>

<template>
  <useHead>
    <title>{{ $t('pages.rubrics.title') }}</title>
  </useHead>

  <PageLayout>
    <template #sticky-header>
      <PageHeader :title="$t('pages.rubrics.title')">
        <template #actions>
          <Button :to="{ name: 'rubrics-create' }" volume="loud">
            <PhPlus weight="bold" />
            {{ $t('actions.create') }}
          </Button>
        </template>
      </PageHeader>
    </template>

    <Empty v-if="isLoading" :title="$t('messages.loading')" size="xl">
      <Loading />
    </Empty>

    <Empty
      v-if="!rubrics?.length && !isLoading"
      :title="$t('messages.noModel', { model: $t('models.rubrics') })"
      size="xl"
      muted
    />

    <ul v-else class="space-y-2">
      <Card v-for="rubric in rubrics" :key="rubric.id" as="li">
        <template #title>
          <h4>
            <RouterLink
              :to="{ name: 'rubrics-edit', params: { id: rubric.id } }"
            >
              {{ rubric.name }}
            </RouterLink>
          </h4>
        </template>

        <template #actions>
          <Button
            :to="{ name: 'rubrics-edit', params: { id: rubric.id } }"
            size="sm"
          >
            <PhPencilSimpleLine weight="duotone" />
            {{ $t('actions.edit') }}
          </Button>
        </template>

        <DetailList
          :entries="rubric"
          :fields="['createdAt']"
          i18n-prefix="fields.rubric"
        >
          <template #createdAt-value="{ value }">
            <DateTimeFormat v-if="value" :date="value" />
          </template>
        </DetailList>
      </Card>
    </ul>
  </PageLayout>
</template>
