<script setup lang="ts">
import { ref, inject } from 'vue'
import { useIntersectionObserver } from '@vueuse/core'

const header = ref(null)
const footer = ref(null)

const headerIsStuck = ref(false)
const footerIsStuck = ref(false)

const intersectionOptions = {
  root: inject('pageContainer', null),
  threshold: 1,
}

useIntersectionObserver(
  header,
  ([{ intersectionRatio }]) => {
    headerIsStuck.value = intersectionRatio < 1
  },
  intersectionOptions
)

useIntersectionObserver(
  footer,
  ([{ intersectionRatio }]) => {
    footerIsStuck.value = intersectionRatio < 1
  },
  intersectionOptions
)
</script>
<template>
  <main class="flex grow flex-col gap-4 py-4 lg:gap-8 lg:py-8">
    <!-- Header -->
    <div
      v-if="$slots['sticky-header']"
      ref="header"
      class="sticky -top-px z-10 flex justify-center p-4 lg:px-8"
      :class="{
        'bg-milk/20 backdrop-blur-xl elevation-3 elevation-lavender-400/20':
          headerIsStuck,
      }"
    >
      <div class="w-full max-w-[var(--page-max-w)]">
        <slot name="sticky-header" />
      </div>
    </div>

    <!-- Body -->
    <div class="flex grow justify-center px-4 lg:px-8">
      <div class="w-full max-w-[var(--page-max-w)]">
        <slot />
      </div>
    </div>

    <!-- Footer -->
    <div
      v-if="$slots['sticky-footer']"
      ref="footer"
      class="sticky -bottom-px z-10 flex flex-col items-center p-4 lg:px-8"
      :class="{
        'bg-milk/20 backdrop-blur-xl elevation-3 elevation-lavender-800/20':
          footerIsStuck,
      }"
    >
      <div class="flex w-full max-w-[var(--page-max-w)] justify-end">
        <slot name="sticky-footer" />
      </div>
    </div>
  </main>
</template>
