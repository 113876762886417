<script setup lang="ts">
import { ref, watch, provide } from 'vue'
import { useRoute } from 'vue-router'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import AppMenu from './AppMenu.vue'
import AppHeader from './AppHeader.vue'

const pageContainer = ref(null)
provide('pageContainer', pageContainer)

const breakpoints = useBreakpoints(breakpointsTailwind)
const isCompactMode = breakpoints.smaller('lg')

const menuIsOpen = ref(false)
const toggleMenu = (value?: boolean) => {
  menuIsOpen.value = typeof value === 'boolean' ? value : !menuIsOpen.value
}

const route = useRoute()
watch(
  () => route.path,
  () => menuIsOpen.value && toggleMenu(false)
)
</script>

<template>
  <div
    class="pattern-sheet relative flex h-[100dvh] w-screen items-stretch justify-stretch gap-4 overflow-hidden p-4"
  >
    <Transition
      enter-active-class="transition-[transform,opacity] duration-300 ease-out-cubic"
      enter-from-class="-translate-x-1/2 opacity-0"
      enter-to-class="translate-x-0 opacity-1"
      leave-active-class="transition-[transform,opacity] duration-200 ease-in-cubic"
      leave-from-class="translate-x-0 opacity-1"
      leave-to-class="-translate-x-1/4 opacity-0"
    >
      <AppMenu
        v-show="!isCompactMode || menuIsOpen"
        class="w-[var(--app-menu-w)]"
        :class="{ 'absolute inset-4 top-10': isCompactMode }"
      />
    </Transition>

    <div
      class="relative flex h-full w-full grow flex-col gap-4 transition-transform duration-300 ease-in-out-cubic"
      :class="{
        'ml-4 translate-x-[var(--app-menu-w)]': isCompactMode && menuIsOpen,
      }"
    >
      <AppHeader v-if="isCompactMode" @toggle-app-menu="toggleMenu" />

      <div
        class="relative flex grow flex-col overflow-hidden rounded-xl bg-lavender-50 elevation-3 elevation-lavender-400/20"
      >
        <!-- Open-Menu Overlay -->
        <Transition
          enter-active-class="transition-opacity duration-200 ease-out-cubic"
          enter-from-class="opacity-0"
          enter-to-class="opacity-1"
          leave-active-class="transition-opacity duration-200 ease-in-cubic"
          leave-from-class="opacity-1"
          leave-to-class="opacity-0"
        >
          <div
            v-show="isCompactMode && menuIsOpen"
            class="absolute inset-0 z-40 h-full w-full bg-milk/40 backdrop-grayscale"
            @click="toggleMenu(false)"
          ></div>
        </Transition>

        <!-- Page Container -->
        <div
          ref="pageContainer"
          class="relative flex grow flex-col overflow-y-auto"
          :class="{
            'pointer-events-none select-none': isCompactMode && menuIsOpen,
          }"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
