<script setup lang="ts" generic="T extends object">
import { computed } from 'vue'

// TODO: make fields K & [key: string]
// TODO: label class
// TODO: value class
const props = defineProps<{
  entries: T
  fields?: string[]
  i18nPrefix?: string
  hideLabels?: boolean
}>()

const entries = computed(() => Object.entries(props.entries))

const normalizedFields = computed(() => props.fields?.filter(Boolean))

const entriesFields = computed(() =>
  normalizedFields.value
    ? normalizedFields.value.map(
        (field) =>
          entries.value.find(([key]) => key === field) ?? [field, undefined]
      )
    : entries.value
)

const item = computed(() => props.entries)
</script>

<template>
  <div class="@container">
    <dl class="grid gap-4 @md:grid-cols-2 @xl:grid-cols-3 @2xl:grid-cols-4">
      <div
        v-for="([key, value], i) in entriesFields"
        :key="key"
        class="flex flex-col gap-1"
        :class="{
          'text-muted': value === null || typeof value === 'undefined',
        }"
      >
        <span v-if="$slots[`${key}-icon`]" class="flex text-[1.5em]">
          <slot :name="`${key}-icon`" />
        </span>

        <div class="flex flex-col">
          <dt
            class="text-muted text-b3 order-2"
            :class="{
              'sr-only': hideLabels,
            }"
          >
            <slot
              :name="`${key}-label`"
              :item="item"
              :label-key="key"
              :i18n-prefix="i18nPrefix"
              :i18n-key="`${i18nPrefix}.${key}`"
              :index="i"
              :label="i18nPrefix ? $t(`${i18nPrefix}.${key}`) : key"
            >
              {{ i18nPrefix ? $t(`${i18nPrefix}.${key}`) : key }}
            </slot>
          </dt>

          <dd class="min-h-5">
            <template v-if="value === null || typeof value === 'undefined'">
              <slot
                name="fallback"
                :item="item"
                :entry="[key, value]"
                :entry-key="key"
                :value="value"
                :index="i"
              >
                {{ $t('messages.notAvailable') }}
              </slot>
            </template>

            <template v-else>
              <slot
                :name="`${key}-value`"
                :item="item"
                :entry="[key, value]"
                :entry-key="key"
                :value="value"
                :index="i"
              >
                {{ value }}
              </slot>
            </template>
          </dd>
        </div>
      </div>
    </dl>
  </div>
</template>
