<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useApi } from '@/plugins/api'
import { Button, Card, Empty, Loading, PageLayout } from '@/components'
import { Job } from '@@/types'
import { PhDownloadSimple } from '@phosphor-icons/vue'
import usePaginatedResults from '../usePaginatedResults'

const route = useRoute()
const jobId = computed(() => route.params.id)

const { data: job, isLoading: jobIsLoading } = useApi<Job>(
  `/jobs/${jobId.value}`
)

const { results, hasMore, loadMore, isLoading } = usePaginatedResults<Job>(
  `/jobs/${jobId.value}/candidates/import`
)
const noResults = computed(() => results.value.length === 0)
</script>

<template>
  <useHead>
    <title>{{ $t('pages.candidatesImport.title') }}</title>
  </useHead>

  <PageLayout>
    <template #sticky-header>
      <PageHeader
        :title="$t('pages.candidatesImport.title')"
        :breadcrumbs="[
          { text: $t('nav.jobs'), to: { name: 'jobs' } },
          { text: job?.name, to: { name: 'jobs-edit', id: job?.id } },
        ]"
        :loading="jobIsLoading"
      >
      </PageHeader>
    </template>

    <Empty
      v-if="noResults && isLoading"
      :title="$t('messages.loading')"
      size="xl"
    >
      <Loading />
    </Empty>

    <Empty
      v-else-if="noResults && !isLoading"
      :title="$t('messages.noModel', { model: $t('models.candidates') })"
      size="xl"
      muted
    />

    <section v-else>
      <ul>
        <Card
          v-for="candidate in results"
          :key="candidate.id"
          as="li"
          :class="{ 'opacity-50': candidate.importedAt }"
        >
          <template #title>
            <h4>{{ candidate.name }}</h4>
          </template>

          <template #actions>
            <Button v-if="!candidate.importedAt" size="sm">
              <PhDownloadSimple weight="regular" />
              {{ $t('actions.import') }}
            </Button>
          </template>
        </Card>
      </ul>

      <Button
        v-if="hasMore"
        class="w-full"
        :loading="isLoading"
        @click="loadMore()"
      >
        {{ $t('actions.loadMore') }}
      </Button>
    </section>
  </PageLayout>
</template>
